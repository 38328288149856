import { QuizB2BData } from '@lp-root/src/data/quiz-typeform.model';
import { Col } from '@web/atomic';
import { Form, FormData } from '@web/atomic/legacy/obj.form';
import * as React from 'react';
import { FormItemRow } from './form-item.component.style';
import { useFormController } from './quiz-typeform.container';

interface MultistepFormProps {
  resultsPath: string;
}

const MultistepForm: React.FunctionComponent<MultistepFormProps> = (props) => {
  const { initialized, onNext, onBack, handleSubmit, viewToShow, viewToShowIsLast, questions, initialValues } = useFormController(
    props.resultsPath
  );

  if (!initialized) {
    return null;
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {questions.map((FormField, idx) => {
          return (
            <React.Fragment key={idx}>
              <FormItemRow mb center="xs" hidden={FormField === viewToShow}>
                <Col xs={12} sm={10} md={9} lg={6}>
                  <FormField
                    onNext={(formData: FormData<QuizB2BData>) => onNext(FormField, formData)}
                    onBack={(formData: FormData<QuizB2BData>) => onBack(FormField, formData)}
                    viewToShow={viewToShow}
                    isLastQuestion={FormField === viewToShow && viewToShowIsLast}
                    prefilledValues={initialValues}
                  />
                </Col>
              </FormItemRow>
            </React.Fragment>
          );
        })}
      </Form>
    </>
  );
};

export default MultistepForm;
