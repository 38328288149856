/* eslint-disable sonarjs/no-duplicate-string */

export interface QuizB2BData {
  term?: 'accept';

  name: string;
  company: string;
  birthdate: string;
  cpf: string;
  gender: string;
  email?: string;
  origin?: string;
  weekFrequency: WeekFrequency;
  anxiety: Anxiety;
  extraData?: JSON;
  sleepDisturb: SleepDisturb;
  othersPerception: OthersPerception;
  interference: Interference;
  satisfaction: Satisfaction;
  concerned: Concerned;
  sleepProblemAge: SleepProblemAge;
  controlConcerns: ControlConcerns;
  discouragement: Discouragement;
  pleasure: Pleasure;
  initialInsomnia: InitialInsomnia;
  middleInsomnia: MiddleInsomnia;
  terminalInsomnia: TerminalInsomnia;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export enum InitialInsomnia {
  None = 'Nenhuma',
  Slight = 'Leve',
  Medium = 'Moderada',
  Serious = 'Grave',
  Severe = 'Muito Grave',
}

export enum MiddleInsomnia {
  None = 'Nenhuma',
  Slight = 'Leve',
  Medium = 'Moderada',
  Serious = 'Grave',
  Severe = 'Muito Grave',
}

export enum TerminalInsomnia {
  None = 'Nenhuma',
  Slight = 'Leve',
  Medium = 'Moderada',
  Serious = 'Grave',
  Severe = 'Muito Grave',
}

export enum Satisfaction {
  VerySatisfied = 'Muito Satisfeito/a',
  Satisfied = 'Satisfeito/a',
  Neutral = 'Mais ou menos satisfeito',
  Insatisfied = 'Insatisfeito/a',
  VeryInsatisfied = 'Muito insatisfeito/a',
}

export enum Interference {
  None = 'Não interfere',
  Small = 'Pouco',
  Somehow = 'De algum modo',
  Great = 'Muito',
  Extreme = 'Extremamente',
}

export enum OthersPerception {
  None = 'Não percebem',
  Small = 'Pouco',
  Somehow = 'De algum modo',
  Great = 'Muito',
  Extreme = 'Extremamente',
}

export enum Concerned {
  None = 'Não estou preocupado/a',
  Small = 'Um pouco',
  Somehow = 'De algum modo',
  Great = 'Muito',
  Extreme = 'Extremamente',
}

export enum SleepProblemAge {
  None = 'Não tenho nenhuma dificuldade com sono',
  LessOneMonth = 'Menos de 1 mês',
  BetweenOneAndThree = '1 - 3 meses',
  BetweenThreeAndSix = '3 - 6 meses',
  BetweenSixAndTwelve = '6 meses - 1 ano',
  MoreThanOneYear = 'Mais de 1 ano',
}

export enum WeekFrequency {
  LessOneDay = 'Menos de 1 dia por semana',
  BetweenOneAndTwo = '1 - 3 dias',
  BetweenThreeAndFour = '3 - 4 dias',
  MoreThanFiveDays = '5 dias ou mais',
}

export enum SleepDisturb {
  Future = 'Preocupações de eventos futuros',
  Awake = 'Preocupações sobre não dormir',
  Pain = 'Dores ou sensações em seu corpo',
  Noise = 'Barulho',
  Light = 'Luz',
}

export enum Pleasure {
  None = 'Nenhuma vez',
  FewDays = 'Poucos dias',
  Majority = 'Mais da metade dos dias',
  Everyday = 'Quase todos os dias',
}

export enum Discouragement {
  None = 'Nenhuma vez',
  FewDays = 'Poucos dias',
  Majority = 'Mais da metade dos dias',
  Everyday = 'Quase todos os dias',
}

export enum Anxiety {
  None = 'Nenhuma vez',
  FewDays = 'Poucos dias',
  Majority = 'Mais da metade dos dias',
  Everyday = 'Quase todos os dias',
}

export enum ControlConcerns {
  None = 'Nenhuma vez',
  FewDays = 'Poucos dias',
  Majority = 'Mais da metade dos dias',
  Everyday = 'Quase todos os dias',
}

export enum Gender {
  Masculin = 'Masculino',
  Feminin = 'Feminino',
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export enum SleepProblemFrequency {
  LessThanOne = 'Menos de 1 dia por semana',
  BetweenOnendTwo = '1-2 dias',
  BetweenThreeAndFour = '3-4 dias',
  MoreThanFive = '5 dias ou mais',
}

export enum SleepProblemChallenge {
  FallSleep = 'Pegar no sono',
  WakeUpMiddle = 'Acordar no meio da noite',
  WakeUpEarly = 'Acordar antes da hora planejada e não conseguir voltar a dormir',
  WakeUpTired = 'Noite não restauradora (acorda cansado)',
}

export enum TimeInterval {
  Till15 = 'Até 15 minutos',
  Till30 = '16 - 30 minutos',
  Till45 = '31 - 45 minutos',
  Till60 = '46 - 60 minutos',
  More60 = 'Mais de 61 minutos',
}

export enum SleepProblemCause {
  FutureWorry = 'Preocupações de eventos futuros',
  NotSleepingWorry = 'Preocupações sobre não dormir',
  PhysicalPain = 'Dores ou sensações em seu corpo',
  Noise = 'Barulho',
  Light = 'Luz',
}

export enum SleepCondition {
  Bipolar = 'Transtorno bipolar',
  Seizure = 'Distúrbios convulsivos',
  Parasomnia = 'Parassonia grave (movimentos anormais durante o sono, como sonambulismo)',
  Apnea = 'Apneia do sono não tratada',
}

export enum SleepSolution {
  Meditation = 'Tentei meditação',
  SleepHygiene = 'Fiz higiene do sono',
  Doctor = 'Fui a um médico',
  Medicine = 'Tomei remédio',
  CBTI = 'Já fiz TCC-i (Terapia Cognitivo-Comportamental para insônia)',
}
