import { isValidDate } from '../date/parse-to-date';
import { toHourString } from '../date/to-hour';
import { HasuraDate, HasuraTimestamptz, HasuraTimetz } from '../remote-graphql-types.extra';

export class HasuraScalarMappers {
  /**
   * Maps to "an array" (type _text on hasura) to be inserted on hasura
   * @see https://hasura.io/docs/latest/mutations/postgres/insert/#insert-an-object-with-an-array-field
   */
  static mapToTextArray = (strings: Set<string> | string[]): string[] => {
    if (!strings) {
      return `{}` as unknown as string[];
    }
    // SMELL_ALERT: WHY this crazy type casting?
    // even though strings is a string[], when inserting it on hasura, you
    // should send the array as a string with the following format
    // {ITEM1,ITEM2,ITEM3}
    // So why do we cast it to string[]? Even though hasura expects as string, the
    // typescript expects a string[], so we cast it for the typescript part of the
    // code
    return `{${Array.from(strings).join(',')}}` as unknown as string[];
  };

  static mapToDate = (date: Date | string): HasuraDate => {
    if (!date) {
      return date as HasuraDate;
    }

    if (isValidDate(date as Date)) {
      // SMELL_ALERT: WHY this crazy type casting?
      // We know that Hasura will save type 'date' as 'YYYY-MM-DD', but you can
      // send a "Date" to hasura and then it will handle this cast for you
      return date as unknown as HasuraDate;
    }

    if (typeof date === 'string') {
      const regexForYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
      if (regexForYYYYMMDD.test(date)) {
        return date as HasuraDate;
      } else {
        throw new Error(`Invalid date format, it should be YYYY-MM-DD: ${date}`);
      }
    } else {
      throw new Error(`Invalid input: ${typeof date} , ${date}`);
    }
  };

  static mapToTimestamptz = (timestamptz: Date): HasuraTimestamptz => {
    if (!timestamptz) {
      return timestamptz as unknown as HasuraTimestamptz;
    }

    // SMELL_ALERT: WHY this crazy type casting?
    // We know that Hasura will save type 'timestamptz' as 'YYYY-MM-DDTHH:mm:ss+00:00', but you can
    // send a "Date" to hasura and then it will handle this cast for you
    return timestamptz as unknown as HasuraTimestamptz;
  };

  static mapToTimetz = (date: Date | string): HasuraTimetz => {
    if (!date) {
      return date as HasuraTimetz;
    }

    if (isValidDate(date as Date)) {
      return `${toHourString(date as Date)}:00+00` as HasuraTimetz;
    }

    if (typeof date === 'string') {
      const regexForYYYYMMDD = /^\d{2}:\d{2}:\d{2}$/;
      if (regexForYYYYMMDD.test(date)) {
        return date as HasuraTimetz;
      } else {
        throw new Error(`Invalid date format, it should be HH:mm:ss  ${date}`);
      }
    } else {
      throw new Error(`Invalid input: ${typeof date} , ${date}`);
    }
  };
}
