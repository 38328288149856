import { Row, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const FormItemRow = styled<any>(Row)`
  display: ${(props) => (props.hidden ? 'flex' : 'none')};
  margin-top: ${Spacing.Medium};
  padding-top: ${Spacing.Medium};
  @media all and (min-width: 48em) {
    margin-top: ${Spacing.XXXLarge};
    padding-top: ${Spacing.XXXLarge};
  }
`;
